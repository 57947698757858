const { PDFDocument } = require("pdf-lib");
const oz2 = require("../pdf-templates/2oz.pdf");
const oz4 = require("../pdf-templates/4oz.pdf");
const oz8 = require("../pdf-templates/8oz.pdf");
const oz12 = require("../pdf-templates/12oz.pdf");
const oz12DW = require("../pdf-templates/12ozDW.pdf");
const oz16 = require("../pdf-templates/16oz.pdf");
const oz20 = require("../pdf-templates/20oz.pdf");

const modifyPDFFile = async ({
  file,
  image,
  cupSize,
  drawBorders,
  topWidth,
  bottomLeftEdgePoint,
}) => {
  const { pdfRows } = specs[cupSize];

  if (!pdfRows) {
    throw `PDF TEMPLATE FILE SPECS FOR CUP SIZE ${cupSize} NOT FOUND`;
  }

  // TODO for future refactoring: Extract "actualPdfDoc" calculation into a separate function
  // load file as PDFDocument object
  const pdfTemplate = await PDFDocument.load(file);

  // the document we will actually use and save
  let actualPdfDoc;

  if (drawBorders) {
    // use template pdf with borders already drawn in it
    actualPdfDoc = pdfTemplate;
  } else {
    // make a new pdf document with one page, whose dimensions are the same as the dimensions of template files first page
    actualPdfDoc = await PDFDocument.create();

    const templatePage = pdfTemplate.getPage(0);
    actualPdfDoc.addPage([templatePage.getWidth(), templatePage.getHeight()]);
  }

  const embeddedImage = await actualPdfDoc.embedPng(image);
  const scaleRatio = topWidth / embeddedImage.width;
  const { width, height } = embeddedImage.scale(scaleRatio);
  const page = actualPdfDoc.getPage(0);

  const { x, y } = bottomLeftEdgePoint;

  pdfRows.forEach(
    ({
      xOffsets = [0],
      bendRight = false,
      additionalXOffset = 0,
      additionalYOffset = 0,
    }) => {
      const firstOffset = xOffsets[0];

      xOffsets
        .map((offset) => offset - firstOffset)
        .forEach((offset) => {
          page.drawImage(embeddedImage, {
            x: x + offset + additionalXOffset,
            /*  measurements are made in the coordinate system, where y grows down.
                Pdf-lib uses traditional mathematical coordinate system, where y grows up.
                Thats why we need to "mirror" y coordinate
            */
            y: page.getHeight() - (y + additionalYOffset),
            width,
            height,
            rotate: {
              angle: bendRight ? 90 : -90,
              type: "degrees",
            },
          });
        });
    }
  );

  // save and return modified document
  return await actualPdfDoc.saveAsBase64();
};

/*
  You can find documentation regarding the origin and the purpose of these specs in "documentation/pdfMeasurements/pdfMeasurements.txt"
*/
const specs = {
  "2oz": {
    pdfRows: [
      {
        /*
          unit = pt, measured in "Affinity Photo" software
          reason for this unit - this is the unit used by "pdf-lib" library
        */
        xOffsets: [156.5, 385.5, 614.5, 843.5, 1072.5],
      },
      {
        xOffsets: [275.5, 504.5, 733.5, 962.5, 1191.5],
        additionalXOffset: 133,
        additionalYOffset: 77,
        bendRight: true,
      },
    ],
  },
  "4oz": {
    pdfRows: [
      {
        xOffsets: [111, 330, 549, 767.5, 986.5],
      },
    ],
  },
  "8oz": {
    pdfRows: [
      {
        xOffsets: [17.5, 341.5, 665.5, 989.5],
      },
    ],
  },
  "12oz": {
    pdfRows: [
      {
        xOffsets: [88.5, 481.5, 874],
      },
    ],
  },
  "12ozDW": {
    pdfRows: [
      {
        xOffsets: [178.125, 501.2, 824.25],
      },
    ],
  },
  "16oz": {
    pdfRows: [
      {
        xOffsets: [122, 734.5],
      },
    ],
  },
  "20oz": {
    pdfRows: [
      {
        xOffsets: [183, 673.5],
      },
    ],
  },
};

// Map the name and file which is used
const selectPDF = (pdf) => {
  switch (pdf) {
    case "2oz":
      return oz2;
    case "4oz":
      return oz4;
    case "8oz":
      return oz8;
    case "12oz":
      return oz12;
    case "12ozDW":
      return oz12DW;
    case "16oz":
      return oz16;
    case "20oz":
      return oz20;
    default:
      return "error";
  }
};

export async function loadPDFAndGenerate({ cupSize, ...rest }) {
  const template = selectPDF(cupSize);
  const pdfFile = await readFilePromise(template).catch((err) => {
    console.error(`COULD NOT FIND FILE FOR SIZE: ${cupSize}`);
    console.error(err);

    throw err;
  });

  return modifyPDFFile({
    file: pdfFile,
    cupSize,
    ...rest,
  });
}

function readFilePromise(file) {
  return new Promise((resolve, reject) => {
    try {
      fetch(file).then((response) => {
        response.blob().then((imgBlob) => {
          imgBlob.arrayBuffer().then((imgBuffer) => resolve(imgBuffer));
        });
      });
    } catch (err) {
      console.log(err);
      reject(null);
    }
  });
}
