import React from "react";
import useStore from "../store";
import { useTexts } from "../texts";

import "../styles/loadingIndicator.scss";

const LoadingIndicator = ({ text }) => {
  const { croppingInProgress, pdfSavingInProgress } = useStore();
  const texts = useTexts();

  return (
    (pdfSavingInProgress || croppingInProgress) && (
      <p className="loading-indicator-text">
        <b>{texts(text)}</b>
      </p>
    )
  );
};

export default LoadingIndicator;
