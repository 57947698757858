import { initialPrintingMeasurementsEnhanced } from "./geometryUtils";

const enhancedSpecCache = {};

/* TODO: for possible future refactoring
   There is no reason specs related to 3d model should be bundled and calculated together with the specs related to image manipulation and pdf generating functions
   They could/should be separated
*/
export default function getSpec(size) {
  if (!enhancedSpecCache[size]) {
    const initialSpec = specs[size];

    if (!initialSpec) {
      throw new Error(`Size ${size} not supported`); // SHOULD NOT HAPPEN
    }

    const {
      cupMeasurements,
      printingSideMeasurements,
      correctionDirection = "left",
      paperThickness,
      doubleWall = false,
      smallPrintImageApproximateHeight = 1,
      smallPrintImageAdditionalYOffset = 0,
    } = initialSpec;

    enhancedSpecCache[size] = {
      cupMeasurements,
      printingSideMeasurements: initialPrintingMeasurementsEnhanced(
        printingSideMeasurements,
        correctionDirection,
        smallPrintImageApproximateHeight,
        smallPrintImageAdditionalYOffset
      ),
      size,
      paperThickness,
      doubleWall,
    };
  }

  return enhancedSpecCache[size];
}

export const specs = {
  "2oz": {
    cupMeasurements: {
      /*  "cupMeasurements" are read straight from the given technical drawing of the ready "3d" cup
          These drawings can be found in "documentation/technical_drawings" folder
          These numbers are used in the 3D visualisation model part of the app
          Unit is mm (as used in technical drawings)
      */
      totalHeight: 49,
      topOuterDiameter: 51.32,
      topInnerDiameter: 47.18,
      topTubeInnerDiameter: 2.2,
      bottomDiameter: 36,
      bottomDepth: 4.8,
    },
    printingSideMeasurements: {
      /*
        These coordinates are read from pdf template and are used to make correct benting of any given image for insreting into a pdf template for this size
        Unit is pt, determined using Affinity Photo software
        You can find documentation regarding the origin and the purpose of these specs in "documentation/pdfMeasurements/pdfMeasurements.txt"
      */
      topLeftCorner: { x: 309, y: 371 },
      topRightCorner: { x: 309, y: 820 },
      bottomLeftCorner: { x: 150.5, y: 437 },
      bottomRightCorner: { x: 150.5, y: 752 },
    },
    correctionDirection: "right",
    paperThickness: 0.25,
    smallPrintImageAdditionalYOffset: 5,
    smallPrintImageApproximateHeight: 1.2,
  },
  "4oz": {
    cupMeasurements: {
      totalHeight: 59,
      topOuterDiameter: 61.7,
      topInnerDiameter: 57.08,
      topTubeInnerDiameter: 2.4,
      bottomDiameter: 46.5,
      bottomDepth: 5,
    },
    printingSideMeasurements: {
      topLeftCorner: { x: 294, y: 144 },
      topRightCorner: { x: 294, y: 678 },
      bottomLeftCorner: { x: 101, y: 206 },
      bottomRightCorner: { x: 101, y: 617.5 },
    },
    paperThickness: 0.32,
    smallPrintImageApproximateHeight: 1.5,
  },
  "8oz": {
    cupMeasurements: {
      totalHeight: 93,
      topOuterDiameter: 80,
      topInnerDiameter: 74.71,
      topTubeInnerDiameter: 2.8,
      bottomDiameter: 55,
      bottomDepth: 5,
    },
    printingSideMeasurements: {
      topLeftCorner: { x: 291.5, y: 66.5 },
      topRightCorner: { x: 291.5, y: 757.5 },
      bottomLeftCorner: { x: 6.5, y: 169.5 },
      bottomRightCorner: { x: 6.5, y: 652 },
    },
    paperThickness: 0.3,
    smallPrintImageApproximateHeight: 1.5,
  },
  "12oz": {
    cupMeasurements: {
      totalHeight: 110,
      topOuterDiameter: 89.4,
      topInnerDiameter: 83.74,
      topTubeInnerDiameter: 3,
      bottomDiameter: 60.6,
      bottomDepth: 10,
    },
    printingSideMeasurements: {
      topLeftCorner: { x: 408, y: 25 },
      topRightCorner: { x: 408, y: 797 },
      bottomLeftCorner: { x: 64.5, y: 153 },
      bottomRightCorner: { x: 64.5, y: 667 },
    },
    paperThickness: 0.37,
    smallPrintImageApproximateHeight: 2,
  },
  "12ozDW": {
    cupMeasurements: {
      totalHeight: 99.3,
      topOuterDiameter: 89.4,
      topInnerDiameter: 83.86,
      topTubeInnerDiameter: 3,
      bottomDiameter: 64.55, // Outer diameter on the drawing, the diameter of the label / double wall
      bottomInnerDiameter: 59.03, // Inner diameter of the inner wall
      bottomDepth: 8,
    },
    printingSideMeasurements: {
      topLeftCorner: { x: 455, y: 33.5 },
      topRightCorner: { x: 455, y: 794 },
      bottomLeftCorner: { x: 167, y: 126 },
      bottomRightCorner: { x: 167, y: 699 },
    },
    /**
     * Double wall doesn't really use this property unlike the rest of the cups
     * but this is included just in case.
     */
    paperThickness: 0.3,
    doubleWall: true,
    smallPrintImageApproximateHeight: 0,
  },
  "16oz": {
    cupMeasurements: {
      totalHeight: 152.6,
      topOuterDiameter: 89.6,
      topInnerDiameter: 83.34,
      topTubeInnerDiameter: 3.3,
      bottomDiameter: 59.37,
      bottomDepth: 7.7,
    },
    printingSideMeasurements: {
      topLeftCorner: { x: 518, y: 28 },
      topRightCorner: { x: 518, y: 800.5 },
      bottomLeftCorner: { x: 102, y: 151.5 },
      bottomRightCorner: { x: 102, y: 677 },
    },
    paperThickness: 0.42,
    smallPrintImageApproximateHeight: 2.5,
  },
  "20oz": {
    cupMeasurements: {
      totalHeight: 147,
      topOuterDiameter: 90.1,
      topInnerDiameter: 83.1,
      topTubeInnerDiameter: 3.6,
      bottomDiameter: 63.3,
      bottomDepth: 8.9,
    },
    printingSideMeasurements: {
      topLeftCorner: { x: 621, y: 15 },
      topRightCorner: { x: 621, y: 788 },
      bottomLeftCorner: { x: 162, y: 126 },
      bottomRightCorner: { x: 162, y: 684 },
    },
    paperThickness: 0.46,
    smallPrintImageApproximateHeight: 2.5,
  },
};
