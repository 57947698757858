import React from "react";
import ReactDOM from "react-dom/client";
import { MemoryRouter } from "react-router-dom";
import App from "./App";

const target = document.getElementById("erw-root");

if (target) {
  const id = target.dataset.id;
  const settings = window.erwSettings[id];
  const root = ReactDOM.createRoot(target);
  root.render(
    <React.StrictMode>
      <MemoryRouter>
        <App settings={settings} />
      </MemoryRouter>
    </React.StrictMode>
  );
}
