import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import UploadFile from "../UploadFile";
import Instructions from "../Instructions";

import { useTexts } from "../../texts/index.js";
import useStore from "../../store";

const SetUpDesignPage = () => {
  const navigate = useNavigate();
  const texts = useTexts();
  const { setProcessState } = useStore();
  const [designFileLoaded, setDesignFileLoaded] = useState(false);
  const [loadedFileName, setLoadedFileName] = useState("");

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      setLoadedFileName(event.target.files[0].name);

      // Scss for this is in avanced-product-fields.scss file inside Blooms theme folder
      // TODO: figure out better solution to make functionality happen
      document
        .querySelector(".configurator-opener")
        .classList.add("file-added");
      setDesignFileLoaded(true);
    } else {
      document
        .querySelector(".configurator-opener")
        .classList.remove("file-added");
      setDesignFileLoaded(false);
    }
  };

  useEffect(() => {
    document
      .querySelector("#real-file-input")
      .addEventListener("change", handleFileChange);
  }, []);

  const handleExit = () => {
    document.getElementsByTagName("footer")[0].classList.remove("hide-element");
    document.getElementsByTagName("header")[0].classList.remove("hide-element");
    document
      .getElementsByClassName("configurator-container-plugin")[0]
      .classList.remove("--opened");
  };

  return (
    <>
      <Instructions page={"upload"} showList={false} />
      <UploadFile loadedFileName={loadedFileName} />
      <div className="upload-action-button-container">
        <button
          className={"option-button option-button--dark"}
          onClick={() => {
            setProcessState(1);
            navigate("/home");
          }}
        >
          {texts("back-button")}
        </button>
        <button
          className={"option-button option-button--dark floatright"}
          onClick={handleExit}
          disabled={!designFileLoaded}
        >
          {texts("add-to-shopping-basket")}
        </button>
      </div>
    </>
  );
};

export default SetUpDesignPage;
