import * as Magick from "wasm-imagemagick";

// TODO: Should this be transparent or white?
const additionalSpaceColor = "White";

export default async function cropAndBendImage({
  image,
  cropData,
  printCurveAngle,
  marginPercentages,
  rotationAngle,
  additionalImageData,
}) {
  const croppedImage = await cropImage({
    image,
    cropData,
  });

  const bentImage = await bendImage({
    image: croppedImage.buffer,
    printCurveAngle,
    marginPercentages,
    rotationAngle,
    additionalImageData,
  });

  return { croppedImage, bentImage };
}

const cropImage = async ({ image, cropData }) => {
  // Reading size attributes from file for cropping purposes
  const { imageWidth, imageHeight } = await imageInfo(image);

  const { width, height, x, y } = cropData;
  const rightCropBorder = width + x;
  const bottomCropBorder = height + y;

  const xMarginal = Math.abs(x);
  const yMarginal = Math.abs(y);

  /** array in which we will store commands to be executed by imagemagick
   *  each command is itself an array, so in the end we will have to flatten "commands" array
   */
  const commands = [];

  /**
   * Check if we are cropping image inside its area
   * In that case we don't create white space
   * And can call imagemagick crop
   */

  const cropInsideImageArea =
    x > 0 &&
    y > 0 &&
    rightCropBorder <= imageWidth &&
    bottomCropBorder <= imageHeight;

  if (cropInsideImageArea) {
    // Crop area is inside the image
    const cropCommand = ["-crop", `${width}x${height}+${x}+${y}`, "+repage"];
    commands.push(cropCommand);
  } else {
    // Crop area goes outside image area
    // Not cropping inside area, so need to create space and stuff

    const chopLeftSide = x > 0;
    const chopRightSide = rightCropBorder < imageWidth;
    const chopTop = y > 0;
    const chopBottom = bottomCropBorder < imageHeight;

    const createSpaceOnLeftSide = x < 0;
    const createSpaceOnRightSide = rightCropBorder > imageWidth;
    const createSpaceOnTop = y < 0;
    const createSpaceOnBottom = bottomCropBorder > imageHeight;

    // chopping commands
    if (chopRightSide) {
      const chopRightSideCommandAmount = chopLeftSide
        ? imageWidth - width - xMarginal
        : imageWidth - width + xMarginal;

      commands.push(magickCommands.chopRight(chopRightSideCommandAmount));
    }

    if (chopLeftSide) {
      commands.push(magickCommands.chopLeft(`${xMarginal}x${0}`));
    }

    if (chopTop) {
      commands.push(magickCommands.chopTop(yMarginal));
    }

    if (chopBottom) {
      const chopBottomCommandAmount = createSpaceOnTop
        ? imageHeight - height + yMarginal
        : imageHeight - height - yMarginal;

      commands.push(magickCommands.chopBottom(chopBottomCommandAmount));
    }

    // adding space commands
    if (createSpaceOnLeftSide) {
      commands.push(magickCommands.addSpaceOnLeft(xMarginal));
    }

    if (createSpaceOnRightSide) {
      commands.push(magickCommands.addSpaceOnRight(x + width - imageWidth));
    }

    if (createSpaceOnBottom) {
      commands.push(magickCommands.addSpaceOnBottom(y + height - imageHeight));
    }

    if (createSpaceOnTop) {
      commands.push(magickCommands.addSpaceOnTop(yMarginal));
    }
  }

  return executeMagickCommandPipeline({
    inputImageBuffer: image,
    arrayOfCommands: commands,
    outputFileName: "cropped.png",
  });
};

const bendImage = async ({
  image,
  printCurveAngle,
  rotationAngle = 0,
  marginPercentages,
  additionalImageData,
}) => {
  // Reading size attributes from file for purposes of adding the right amount of margins
  const { imageWidth, imageHeight } = await imageInfo(image);

  const commands = [];

  // add additional marginals commands
  const { top = 0, bottom = 0 } = marginPercentages || {};

  const topMargin = top * imageHeight;

  if (topMargin > 0) {
    commands.push(magickCommands.addSpaceOnTop(topMargin));
  }

  const bottomMargin = bottom * imageHeight;

  if (bottomMargin > 0) {
    commands.push(magickCommands.addSpaceOnBottom(bottomMargin));
  }

  let additionalImageBuffer;
  // add additional small print image to the bottom right corner, if given
  if (additionalImageData) {
    const {
      image: additionalImage,
      scalingFactor,
      additionalYOffset,
    } = additionalImageData;

    additionalImageBuffer = additionalImage;

    const scaledHeight = imageHeight * scalingFactor;

    // we place small print image into a middle of bottom margin, unless it does not fit, then we just put it on bottom
    const yOffset =
      Math.max((bottomMargin - scaledHeight) / 2, 0) + additionalYOffset;

    const addingImageCommand = [
      "additionalFile.png",
      "-gravity",
      "south",
      "-geometry",
      `x${scaledHeight}+${imageWidth / 2}+${yOffset}`,
      "-composite",
    ];

    commands.push(addingImageCommand);
  }

  const arcDistortionArgument = [printCurveAngle, rotationAngle].join(" ");

  const bendCommand = [
    "-virtual-pixel",
    "Transparent",
    "-distort",
    "Arc",
    arcDistortionArgument,
  ];

  commands.push(bendCommand);

  return executeMagickCommandPipeline({
    inputImageBuffer: image,
    additionalImageBuffer,
    arrayOfCommands: commands,
    outputFileName: "out.png",
  });
};

const magickCommands = {
  addSpaceOnTop: (amount) => addSpaceCommand("North", `0x${amount}`),
  addSpaceOnBottom: (amount) => addSpaceCommand("South", `0x${amount}`),
  addSpaceOnLeft: (amount) => addSpaceCommand("West", `${amount}x0`),
  addSpaceOnRight: (amount) => addSpaceCommand("East", `${amount}x0`),

  chopTop: (amount) => chopCommand("North", `0x${amount}`),
  chopBottom: (amount) => chopCommand("South", `0x${amount}`),
  chopLeft: (amount) => chopCommand("West", `${amount}x${0}`),
  chopRight: (amount) => chopCommand("East", `${amount}x${0}`),
};

function chopCommand(gravity, amountArg) {
  return ["-gravity", gravity, "-chop", amountArg];
}

function addSpaceCommand(gravity, amountArg) {
  return [
    "-background",
    additionalSpaceColor,
    "-gravity",
    gravity,
    "-splice",
    amountArg,
  ];
}

/**
 * @param {buffer} inputImageBuffer
 * @param {string[][]} arrayOfCommands
 *      Array of arrays, each represents a part of the command.
 *      Needs to be flatten to a final array of magickCommand
 *      Does NOT contain the beginning ("convert fileName") neither the ending ("outputFileName")
 * @param {string} outputFileName
 * @returns The result of executing all the commands to the input image
 */

function executeMagickCommandPipeline({
  inputImageBuffer,
  additionalImageBuffer,
  arrayOfCommands,
  outputFileName,
}) {
  const inputFiles = [
    {
      // "png" is used at the moment since it suits our needs the best and seems to work well with both png and jpg files
      name: "srcFile.png",
      content: inputImageBuffer,
    },
  ];

  if (additionalImageBuffer) {
    inputFiles.push({
      name: "additionalFile.png",
      content: additionalImageBuffer,
    });
  }

  const finalCommand = [
    "convert",
    "srcFile.png",
    ...arrayOfCommands.flat(),
    outputFileName,
  ];

  return Magick.Call(inputFiles, finalCommand).then(
    ([firstOutputImage]) => firstOutputImage
  );
}

async function imageInfo(imageBuffer) {
  const inputFiles = [{ name: "srcFile.png", content: imageBuffer }];

  const { stdout, exitCode } = await Magick.execute({
    inputFiles,
    commands: "identify srcFile.png",
  });

  if (exitCode !== 0) {
    throw new Error("Could not read file size information and cannot crop.");
  }

  const fileSize = stdout.join("\n").split(" ")[2].split("x");
  const [imageWidth, imageHeight] = fileSize;

  return {
    imageWidth: Number(imageWidth),
    imageHeight: Number(imageHeight),
  };
}
