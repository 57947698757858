import React from "react";
import { Routes, Route } from "react-router-dom";

import Instructions from "../Instructions.jsx";
import ConfigurationButtons from "../PhotoEditor/ConfigurationButtons";
import PhotoEditor from "../PhotoEditor/PhotoEditor.jsx";
import Configurator from "../Configurator.jsx";

import useStore from "../../store";
import { getPhotoEditorSettings } from "../../utils/geometryUtils";
import cropAndBendImage from "../../utils/imageManipulation";
import { useCupSpec, mmToPt } from "../../utils/utils";

import smallPrintImage from "../../assets/small_print_img.png";

const convertMmToPts = (mm) => mmToPt(Math.max(mm, 0));

const SetUpDesignPage = () => {
  const {
    selectedCupSize,
    sourceImage,
    cropData,
    setCroppedImageFor3DPreview,
    setProcessedImageForPrint,
    addSmallPrint,
    cupSizes,
    setCroppingInProgress,
  } = useStore();

  const cupSpec = useCupSpec();
  const selectedCup = cupSizes.find(({ value }) => value === selectedCupSize);
  const { margins } = selectedCup;

  const { top = 0, bottom = 0, left = 0, right = 0 } = margins;

  let leftMarginInPoints = convertMmToPts(-left);
  let rightMarginInPoints = convertMmToPts(right);
  let topMarginInPoints = convertMmToPts(top);
  let bottomMarginInPoints = convertMmToPts(bottom);

  const smallPrintImageAddingNotAllowed =
    smallPrintImageApproximateHeight === 0;

  const {
    straightenedImageHeight,
    printCurveAngle,
    skewAngle,
    smallPrintImageApproximateHeight,
    smallPrintImageAdditionalYOffset,
  } = getPhotoEditorSettings({
    measurementSpec: cupSpec.printingSideMeasurements,
    leftMargin: leftMarginInPoints,
    rightMargin: rightMarginInPoints,
  });

  const cropAreaHeight =
    straightenedImageHeight - topMarginInPoints - bottomMarginInPoints;

  async function makeCrop() {
    const { buffer } = sourceImage;

    if (buffer && cropData) {
      setCroppingInProgress(true);
      setProcessedImageForPrint(null);
      setCroppedImageFor3DPreview(null);

      const marginPercentages = {
        top: topMarginInPoints / cropAreaHeight,
        bottom: bottomMarginInPoints / cropAreaHeight,
      };

      const additionalImageData =
        addSmallPrint && !smallPrintImageAddingNotAllowed
          ? await getSmallPrintImage().then((image) => {
              if (image) {
                return {
                  image,
                  scalingFactor:
                    mmToPt(smallPrintImageApproximateHeight) /
                    straightenedImageHeight,
                  additionalYOffset: smallPrintImageAdditionalYOffset,
                };
              }
            })
          : null;

      // TODO: Error handling i.e. catch clause?
      return new Promise((resolve) => {
        cropAndBendImage({
          image: buffer,
          cropData,
          printCurveAngle,
          marginPercentages,
          rotationAngle: -skewAngle,
          additionalImageData,
        })
          .then(({ croppedImage, bentImage }) => {
            setProcessedImageForPrint(bentImage);
            setCroppedImageFor3DPreview(croppedImage);
          })
          .finally(() => {
            setCroppingInProgress(false);
            resolve("Finish");
          });
      });
    }
  }

  return (
    <>
      <Instructions />
      <ConfigurationButtons makeCrop={makeCrop} />
      <Routes>
        <Route index element={<PhotoEditor makeCrop={makeCrop} />} />
        <Route path="three-d" element={<Configurator />} />
      </Routes>
    </>
  );
};

function getSmallPrintImage() {
  return new Promise((resolve, reject) => {
    try {
      fetch(smallPrintImage).then((response) => {
        response.blob().then((imgBlob) => {
          imgBlob.arrayBuffer().then((imgBuffer) => resolve(imgBuffer));
        });
      });
    } catch (err) {
      console.error(err);
      reject(null);
    }
  });
}

export default SetUpDesignPage;
