import React from "react";

import "../styles/switchButton.scss";

import useStore from "../store";

const SwitchButton = ({ buttonText, onClick, name }) => {
  const { croppingInProgress } = useStore();

  return (
    <button
      className={`switch-button ${name}`}
      onClick={onClick}
      disabled={croppingInProgress}
    >
      {buttonText}
    </button>
  );
};

export default SwitchButton;
