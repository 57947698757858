import React from "react";

import "../styles/stateIndicator.scss";

import useStore from "../store.js";

const StateIndicator = () => {
  const { processState } = useStore();
  const stateClass = processState === 1 ? "not-done" : "";
  const checkMark = processState === 2 ? "checkMark" : "first-state ";

  return (
    <div className="stateIndicator-container">
      <div className={`dot ${checkMark}`}></div>
      <span className={stateClass}></span>
      <div className={`dot second-state ${stateClass}`}></div>
    </div>
  );
};

export default StateIndicator;
