import useStore from "../store";
import en from "./en.json";
import fi from "./fi.json";

const texts = {
  en,
  fi,
};

export const languages = [
  {
    key: "en",
    name: "EN",
  },
  {
    key: "fi",
    name: "FI",
  },
];

export function useTexts() {
  const lang = useStore((store) => store.lang);
  const langTexts = texts[lang] || {};

  return (key) => langTexts[key] || "";
}
