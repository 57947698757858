import React from "react";

import { useTexts } from "../texts";
import useStore from "../store.js";
import "../styles/instructions.scss";

const Instructions = ({ page, showList }) => {
  const texts = useTexts();
  const { cupType, selectedCupSize, cupSizes } = useStore();
  const cup = cupSizes.find(({ value }) => value === selectedCupSize);

  const link = `${
    window.location.origin
  }/wp-content/uploads/2022/08/Cupbio_${cup.label
    .toLowerCase()
    .replaceAll(" ", "")}_${cup.value
    .toLowerCase()
    .replaceAll(" ", "")}_template.pdf`;

  return (
    <div className="instructions-container">
      <h2>{texts(`${page}-title`).toUpperCase()}</h2>
      <h5>
        {cupType} {texts(cup.value)}
      </h5>
      <span>{texts(`${page}-introduction`)}</span>

      {showList && (
        <ol>
          <li>
            {texts("instructions-ins0-1")}{" "}
            <a href={link} target="_blank" rel="noreferrer">
              {texts("instructions-ins0-2")}
            </a>{" "}
            {texts("instructions-ins0-3")}
          </li>
          <li>{texts("instructions-ins1")}</li>
          <li>{texts("instructions-ins2")}</li>
        </ol>
      )}
    </div>
  );
};

Instructions.defaultProps = {
  page: "instructions",
  showList: true,
};

export default Instructions;
