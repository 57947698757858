import React from "react";
import ModelViewer from "./ModelViewer.jsx";
import "../styles/configurator.scss";

export default function Configurator() {
  return (
    <div className="configurator">
      <ModelViewer />
    </div>
  );
}
