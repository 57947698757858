import React /* Suspense */ from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";

import { useNavigate } from "react-router-dom";

import useStore from "../store";
import { useTexts } from "../texts";

import GeometricCup from "./GeometricCup.jsx";
import SwitchButton from "./SwitchButton";
import LoadingIndicator from "./LoadingIndicator";

import "../styles/modelViewer.scss";
import { LinearToneMapping } from "three";

// camera (or cup?) position should depend on the cup size?
const cameraPositionY = 0;
const cameraPositionZ = 5;

export default function ModelViewer() {
  const texts = useTexts();
  const navigate = useNavigate();
  const { croppingInProgress, pdfSavingInProgress } = useStore();

  return (
    <div className="product-view">
      <LoadingIndicator text={"processingImage"} />
      <div
        className={`model-viewer ${
          pdfSavingInProgress || croppingInProgress ? "blur-01" : ""
        }`}
      >
        <SwitchButton
          buttonText={texts("addChangePhoto")}
          onClick={() => navigate("/home")}
          name={"addPicture"}
        />

        <Canvas
          camera={{
            fov: 25,
            position: [0, cameraPositionY, cameraPositionZ],
          }}
          // I have no idea what this actually does but it makes 3D colours less washed out
          onCreated={({ gl }) => {
            gl.toneMapping = LinearToneMapping;
          }}
          dpr={Math.max(window.devicePixelRatio, 2)}
        >
          <Scene />
        </Canvas>
      </div>
    </div>
  );
}

function Scene() {
  const selectedCupSize = useStore((state) => state.selectedCupSize);
  const controls = React.useRef();

  React.useEffect(() => {
    // reset orbit controls everytime the size of a cup changes
    if (controls.current) {
      controls.current.reset();
    }
  }, [selectedCupSize]);

  return (
    <group position={[0, -1, 0]}>
      {/* These lights are just kind of randomly added based on look and feel. */}
      <ambientLight color="white" intensity={0.5} />

      <directionalLight
        intensity={0.5}
        position={[0, 0, 13]}
        lookAt={[0, -10, 0]}
        color="white"
      />
      <GeometricCup />
      <OrbitControls
        ref={controls}
        minDistance={8}
        maxDistance={15}
        enablePan={false}
        enableDamping={true}
        enableRotate={false}
        damping={0.3}
      />
    </group>
  );
}
