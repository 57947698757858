import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { loadPDFAndGenerate } from "../../utils/pdfUtils.js";
import { useCupSpec, downloadFile, mmToPt } from "../../utils/utils";
import { getPhotoEditorSettings } from "../../utils/geometryUtils";

import { useTexts } from "../../texts/index.js";
import useStore, { marginDirections } from "../../store";

const convertMmToPts = (mm) => mmToPt(Math.max(mm, 0));

const showMarginAsInputDefaultValue = marginDirections.reduce(
  (acc, direction) => ({
    ...acc,
    [direction]: false,
  }),
  {}
);

const ConfigurationButtons = ({ makeCrop }) => {
  const {
    selectedCupSize,
    sourceImage,
    setSourceImage,
    processedImageForPrint,
    drawBorderOnPDF,
    croppingInProgress,
    cupSizes,
    setCropData,
    cropData,
    setCanvasData,
    setMargins,
    setProcessState,
    pdfSavingInProgress,
    setPdfSavingInProgress,
  } = useStore();

  const texts = useTexts();
  const cupSpec = useCupSpec();
  const navigate = useNavigate();

  const selectedCup = cupSizes.find(({ value }) => value === selectedCupSize);
  const { margins } = selectedCup;
  const { left = 0, right = 0 } = margins;

  const imgFileInput = useRef(null);
  const [showMarginAsInput, setShowMarginAsInput] = useState(
    showMarginAsInputDefaultValue
  );

  let leftMarginInPoints = convertMmToPts(-left);
  let rightMarginInPoints = convertMmToPts(right);

  const marginsAreBeingUpdated = marginDirections.some(
    (direction) => showMarginAsInput[direction]
  );

  useEffect(() => {
    setCropData(null);
    setCanvasData(null);
  }, [sourceImage, cupSpec, margins, setCanvasData, setCropData]);

  useEffect(() => {
    if (selectedCupSize === "2oz") {
      setMargins(left, 0);
      setShowMarginAsInput((prevValue) => ({
        ...prevValue,
        left: false,
      }));
    }
  }, [selectedCupSize, left, setMargins]);

  useEffect(() => {
    if (pdfSavingInProgress && processedImageForPrint) {
      handlePDFSave();
    }
  }, [processedImageForPrint]);

  const handlePDFSave = () => {
    loadPDFAndGenerate({
      image: processedImageForPrint.buffer,
      cupSize: cupSpec.size,
      drawBorders: drawBorderOnPDF,
      topWidth,
      bottomLeftEdgePoint,
    }).then((file) => {
      const b64Chunk = "data:application/pdf;base64," + file;
      fetch(b64Chunk)
        .then((res) => res.blob())
        .then((blob) => downloadFile(blob))
        .then(() => setProcessState(2))
        .then(() => navigate("/upload"));
      setPdfSavingInProgress(false);
    });
  };

  const { topWidth, bottomLeftEdgePoint } = getPhotoEditorSettings({
    measurementSpec: cupSpec.printingSideMeasurements,
    leftMargin: leftMarginInPoints,
    rightMargin: rightMarginInPoints,
  });

  const handleImageSelect = async (event) => {
    event.preventDefault();
    const [imgFile] = event.target.files;

    if (imgFile) {
      const buffer = new Uint8Array(await imgFile.arrayBuffer());
      const reader = new FileReader();
      reader.onload = () => {
        setSourceImage({
          buffer,
          data: reader.result,
        });
      };
      reader.readAsDataURL(imgFile);
    }
  };

  const { data } = sourceImage;

  return (
    <div>
      <div className="configuration-buttons">
        <div className="option-group">
          <input
            type="file"
            accept=".png, .jpg, .jpeg"
            ref={imgFileInput}
            style={{ display: "none" }}
            onChange={handleImageSelect}
          />
          <button
            onClick={() => {
              imgFileInput.current.click();
            }}
            disabled={croppingInProgress}
            className="option-button"
          >
            {texts("loadNewImage")}
          </button>
          <button
            disabled={!data || pdfSavingInProgress || marginsAreBeingUpdated}
            onClick={async () => {
              setPdfSavingInProgress(true);
              await makeCrop();

              if (!cropData && processedImageForPrint) {
                handlePDFSave();
              }
            }}
            className={"option-button option-button--dark floatright"}
          >
            {texts("saveAsPDF")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfigurationButtons;
