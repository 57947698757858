import React from "react";

import useStore from "../store.js";
import { languages } from "../texts";

export default function LangSelector() {
  const { lang, setLang } = useStore();

  return (
    <div className="lang-options">
      {languages.map(({ key, name }) => (
        <button
          type="button"
          key={key}
          className={key === lang ? "active" : ""}
          onClick={() => setLang(key)}
        >
          {name}
        </button>
      ))}
    </div>
  );
}
