import specs from "./cupSpecs.js";
import useStore from "../store";

// const mmToPixelRatio = 3.779527559055118;
// export const mmToPixels = (mm) => mm * mmToPixelRatio;
// export const pixelsToMm = (px) => px / mmToPixelRatio;

const mmToPtRatio = 2.8346456693;
export const mmToPt = (mm) => mm * mmToPtRatio;
export const ptsToMm = (mm) => mm / mmToPtRatio;

export const useCupSpec = () => {
  const cupSize = useStore((store) => store.selectedCupSize);

  return specs(cupSize);
};

export const downloadFile = async (blob) => {
  const URL = window.URL.createObjectURL(blob);
  const el = document.createElement('a');
  el.download = 'cup_model_file.pdf';
  el.href = URL;
  el.click();
  window.URL.revokeObjectURL(URL);
};