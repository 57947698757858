import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import ConfiguratorHeader from "./components/configuratorHeader.jsx";
import Wrapper from "./components/wrapper.jsx";
import useStore from "./store.js";

import SetUpDesignPage from "./components/Pages/SetUpDesignPage.jsx";
import UploadDesignPage from "./components/Pages/UploadDesignPage";
import StateIndicator from "./components/StateIndicator";

import "./styles/styles.scss";

function App({ settings }) {
  const {
    sourceImage,
    setCroppedImageFor3DPreview,
    setProcessedImageForPrint,
    setCupSize,
    setCupType,
    setLang,
  } = useStore();

  // In site editor you can defien cup type by passing type argument to shortcode
  useEffect(() => {
    setCupSize(settings.size);
    setCupType(settings.type);
    setLang(settings.lang);
  }, []);

  useEffect(() => {
    setProcessedImageForPrint(null);
    setCroppedImageFor3DPreview(null);
  }, [sourceImage, setCroppedImageFor3DPreview, setProcessedImageForPrint]);

  return (
    <div className="app">
      <ConfiguratorHeader />
      <StateIndicator />
      <Wrapper>
        <Routes>
          <Route index element={<Navigate replace to="home" />} />
          <Route path="home/*" element={<SetUpDesignPage />} />
          <Route exact path="upload" element={<UploadDesignPage />} />
        </Routes>
      </Wrapper>
    </div>
  );
}

export default App;
