// we are using "decimal.js" library to achieve the best precision for intermidiate numerical calculations
import Decimal from "decimal.js";

const sq = (x) => x.pow(2);

const PI = Decimal.acos(-1);

const radiansToDegree = (angleInRadians) => angleInRadians.times(180).div(PI);

/*
  Recursive way of turning a decimal object, or an object with all properties (possibly nested) decimal objects, to the number / similar objects where all these properties are converted to plain js numbers.
  Also useful for debugging
*/
const decimalObjectToNumberObject = (decimalObject) => {
  // NOTE: non-strict equality is on purpose here (catches both undefined and null but nothing else)
  // eslint-disable-next-line
  if (decimalObject == undefined) {
    return decimalObject;
  }

  // if already a Decimal object, just convert it to number
  if (Decimal.isDecimal(decimalObject)) {
    return decimalObject.toNumber();
  }

  // otherwise apply the same function recursively to every key of this object
  return Object.keys(decimalObject).reduce((acc, key) => {
    const value = decimalObject[key];
    const strippedValue = Decimal.isDecimal(value)
      ? value.toNumber() // TODO: this case seems to be unnecessary since should be handled by previous "if" clause, but dont have time to test now, so leaving a note into the future
      : decimalObjectToNumberObject(value);

    return {
      ...acc,
      [key]: strippedValue,
    };
  }, {});
};

const ordinaryPointToDecimalPoint = ({ x, y }) => ({
  x: new Decimal(x),
  y: new Decimal(y),
});

// distance between two points in the plane (given as a pair of Decimal objects)
const distance = (p1, p2) =>
  sq(p2.x.minus(p1.x))
    .plus(sq(p2.y.minus(p1.y)))
    .sqrt();

// slope of line going through points point1 and point2
const getLineSlope = (point1, point2) =>
  point2.y.minus(point1.y).div(point2.x.minus(point1.x));

const getPerpendicularLineSlope = (slope) => new Decimal(-1).div(slope);

// given a point and a value of slope of the line, returns value of y-intercept of the line with that slope that goes through this point
const lineIntercept = (point, slope) => point.y.minus(slope.times(point.x));

// given two points returns a line that goes through these points
const getLine = (point1, point2) => {
  // TODO: could this just use function "getLineBySlopeAndPoint" below after slope is calculated?
  const slope = getLineSlope(point1, point2);

  const line = {
    slope,
    intercept: lineIntercept(point1, slope),
  };

  if (!slope.isFinite()) {
    // vertial line case
    line.x = point1.x;
  }

  return line;
};

// given a point on the line and line's slope returns a line
const getLineBySlopeAndPoint = (slope, point) => {
  if (!slope.isFinite()) {
    return {
      slope: new Decimal(Infinity),
      intercept: new Decimal(-Infinity),
      x: point.x,
    };
  }

  return {
    slope,
    intercept: point.y.minus(point.x.times(slope)),
  };
};

// given slopes of two lines calculates an angle between them (smaller one)
const angleBetweenLines = ({ slope1, slope2 }) => {
  if (!slope1.isFinite()) {
    return new Decimal(1).div(slope2).atan();
  }

  if (!slope2.isFinite()) {
    return new Decimal(1).div(slope1).atan();
  }

  const denominator = slope1.times(slope2).plus(1);

  if (denominator.eq(0)) {
    return PI.div(2);
  }

  return slope2.minus(slope1).div(denominator).abs().atan();
};

/**
 * Given a line and value of x coordinate, returns a y coordinate of the point (x, y) that lies on this line
 * @param { slope: Decimal, intercept: Decimal } line
 *        line given by equation: y = slope * x + intercept
 *        (no support for vertical lines! for vertical line this function makes no sense)
 * @param {Decimal} xCoord
 * @returns y coordinate (as Decimal object)
 */
function lineYCoord({ line, xCoord }) {
  const { slope, intercept } = line;

  return slope.times(xCoord).plus(intercept);
}

/**
 * Given two lines returns a point of their intersection (or null if they do not intersect)
 * @param { slope: Decimal, intercept: Decimal, x: Decimal?} line1
 * @param { slope: Decimal, intercept: Decimal, x: Decimal? } line2
 *        any line is given by equation: Y = slope * X + intercept or X = x (vertical line)
 * @returns null or point in the form {x: Decimal, y: Decimal}
 */

function linesIntersection(line1, line2) {
  const { slope: slope1, intercept: intercept1, x: x1 } = line1;
  const { slope: slope2, intercept: intercept2, x: x2 } = line2;

  // case of parallel lines
  if ((x1 && x2) || slope1 === slope2) {
    // NOTE: Since both x1 and x2 are Decimal objects, if given they are truthy even if represent number 0
    return null;
  }

  if (x1) {
    return { x: x1, y: lineYCoord({ line: line2, xCoord: x1 }) };
  }

  if (x2) {
    return { x: x2, y: lineYCoord({ line: line1, xCoord: x2 }) };
  }

  const xCoord = intercept2.minus(intercept1).div(slope1.minus(slope2));
  const yCoord = lineYCoord({ line: line1, xCoord });

  return {
    x: xCoord,
    y: yCoord,
  };
}

/**
 * Given a cirle and a line returns a point they intersect in
 * @param {radius: Decimal, centerPoint: {x: Decimal, y:Decimal}} circle
 *        circle given by equation: (X - x)^2 + (Y - y)^2 = radius^2
 * @param { slope: Decimal, intercept: Decimal } line
 *        line given by equation: y = slope * x + intercept
 *        (no support for vertical lines)
 * @param {boolean} returnBiggerXCoord
 *        in principle there might be two solutions
 *        if an additional "returnBiggerXCoord" boolean paramter is true, the solution with bigger x coordinate is returned, otherwise the solution with smaller x coordinate is returned
 * @returns {Point: {x: Decimal, y: Decimal}}
 */
function circleAndLineIntersection({
  circle,
  line,
  returnBiggerXCoord = true,
}) {
  const { radius: r, centerPoint } = circle;
  const { x: h, y: k } = centerPoint;

  const { slope: m, intercept: n } = line;

  const nMinusK = n.minus(k); // temp value that is used twice

  // get a, b, c values
  const a = sq(m).plus(1);
  const b = m.times(nMinusK).minus(h).times(2);
  const c = sq(h).plus(sq(nMinusK)).minus(sq(r));

  // get discriminant
  var d = sq(b).minus(a.times(c).times(4));

  if (d.lt(0)) {
    // d < 0 case
    return null; // no solutions. Should not happen whenever we apply this function
  }

  const dSquareRoot = d.sqrt();

  const solutionXCoordinate = (
    returnBiggerXCoord ? dSquareRoot : dSquareRoot.times(-1)
  )
    .minus(b)
    .div(a.times(2));

  const solutionYCoordinate = lineYCoord({ line, xCoord: solutionXCoordinate });

  return { x: solutionXCoordinate, y: solutionYCoordinate };
}

/**
 * Translates a line determined by given points "point1" and "point2" by amount "shiftBy"
 * Parameter "direction" determines if we shift "up" or "down" (with respect to pdf "coordinate system")
 * IMPORTANT: KEEP IN MIND THAT Y AXIS GROWS DOWN IN PDF SPACE, NOT UP, AS USUALLY IN MATH!
 *
 * @param {radius: Decimal, centerPoint: {x: Decimal, y: Decimal}} circle
 *        circle given by equation: (X - x)^2 + (Y - y)^2 = radius^2
 * @param { slope: Decimal, intercept: Decimal } line
 *        line given by equation: y = slope * x + intercept
 *        (no support for vertical lines)
 * @param {"up" | "down"} direction
 *        in principle there might be two solutions
 *        if an additional "returnBiggerXCoord" boolean paramter is true, the solution with bigger x coordinate is returned, otherwise the solution with smaller x coordinate is returned
 * @returns { slope: Decimal, intercept: Decimal } shiftedLine
 */
function translatePointsPerpendicularToLine({
  point1,
  point2,
  shiftBy,
  direction = "up",
}) {
  const line = getLine(point1, point2);
  const lineSlope = line.slope;
  // slope of line parallel to the line that goes through points point1 and point2
  const perpendicularLineSlope = getPerpendicularLineSlope(lineSlope);

  const point1ParalelLineIntercept = lineIntercept(
    point1,
    perpendicularLineSlope
  );
  const point2ParalelLineIntercept = lineIntercept(
    point2,
    perpendicularLineSlope
  );

  // Here because of the inverted y axis direction it gets counter intuitive:
  // negative lineslope means that line is visually increasing! (in pdf "coordinate system"), not decreasing, as usually in math
  const returnBiggerXCoord =
    direction === "up" ? lineSlope.gt(0) : lineSlope.lt(0);

  const translatedPoint1 = circleAndLineIntersection({
    circle: { radius: shiftBy, centerPoint: point1 },
    line: {
      slope: perpendicularLineSlope,
      intercept: point1ParalelLineIntercept,
    },
    returnBiggerXCoord,
  });

  const translatedPoint2 = circleAndLineIntersection({
    circle: { radius: shiftBy, centerPoint: point2 },
    line: {
      slope: perpendicularLineSlope,
      intercept: point2ParalelLineIntercept,
    },
    returnBiggerXCoord,
  });

  return getLine(translatedPoint1, translatedPoint2);
}

/**
 * Given topWidth, bottomWidth and skewHeight of the arc shaped "pdf slot for an image", calculates radius value of the top arc (if continued to a full circle)
 * @param {Decimal} topWidth
 * @param {Decimal} bottomWidth
 * @param {Decimal} skewHeight
 *
 * @returns topRadius
 */
function calcTopRadius({ topWidth, bottomWidth, skewHeight }) {
  return skewHeight.times(topWidth).div(topWidth.minus(bottomWidth));
}

/**
 * Given topWidth and topRadius of the arc shaped "pdf slot for an image", calculates the angle that corresponds to the arc (if continued to a circle)
 * RETURNED ANGLE VALUE IS IN RADIANS
 * @param {Decimal} topWidth
 * @param {Decimal} topRadius
 *
 * @returns angle values in radians
 */
function calcCurveAngle({ topWidth, topRadius }) {
  const cosine = new Decimal(1).minus(sq(topWidth).div(sq(topRadius).times(2))); // cosine of angle we want

  return cosine.acos();
}

/**
 * Given topWidth, skewHeight and curveAngle of the arc shaped "pdf slot for an image", calculates the width of the image before it was bent to the shape of this arc (assuming that its height was equal to "skewHeight")
 * @param {Decimal} topWidth
 * @param {Decimal} skewHeight
 * @param {Decimal} curveAngle (measured in radians)
 *
 * @returns the width of the image before it was bent
 */
function calcStraigtenedImageWidth({ topRadius, skewHeight, curveAngle }) {
  // this is the radius of the smaller subcircle that goes across the bottom edge of the warpped image
  // needed at least as an argument to imagemagicks warpping algorithm
  const bottomRadius = topRadius.minus(skewHeight);
  // this is the radius of the smaller subcircle that goes across the bottom edge of the warpped image
  // needed at least as an argument to imagemagicks warpping algorithm
  const middleRadius = topRadius.plus(bottomRadius).div(2);

  // the width of the image before it is bent
  return middleRadius.times(curveAngle);
}

/**
 * Calculates the points in which a margin line intersects horizontal arc edges of the "pdf slot" ("aihio")
 *
 * @param {{x: Decimal, y: Decimal}} topPoint top edge point of the "pdf slot" on the side we are calculating margin now for
 * @param {{x: Decimal, y: Decimal}} bottomPoint bottom edge point of the "pdf slot" on the side we are calculating margin now
 * @param {{x: Decimal, y: Decimal}} centerPoint center of the big circle a "pdf slot" is a "part off"
 * @param {Decimal} topRadius the radius of the big circle mentioned above that corresponds to the upper arc edge of "pdf slot"
 * @param {Decimal} bottomRadius the radius of the big circle mentioned above that corresponds to the lower arc edge of "pdf slot"
 * @param {Decimal} marginLength how big the margin from the side of "pdf slot" is
 * @param {"up" | "down"} direction in which direction we "shift" the edge to obtain a margin with respect to pdf coordinate space (in which y-axis is growing "down")
 * @returns { topPoint: {x: Decimal, y: Decimal}, bottomPoint: {x: Decimal, y: Decimal}} top and bottom points of the margin line i.e. the new "edge" of the slot, where image should be insterted
 */
function calcMarginEdgePoints({
  topPoint,
  bottomPoint,
  centerPoint,
  topRadius,
  bottomRadius,
  marginLength,
  direction = "up", // in reality will always be called with 'direction: up'
}) {
  try {
    if (marginLength.abs().lt(0.1)) {
      return {
        topPoint,
        bottomPoint,
      };
    }

    const marginLine = translatePointsPerpendicularToLine({
      point1: topPoint,
      point2: bottomPoint,
      shiftBy: marginLength,
      direction,
    });

    const marginLineTopPoint = circleAndLineIntersection({
      circle: { radius: topRadius, centerPoint },
      line: marginLine,
      returnBiggerXCoord: direction === "up",
    });

    const marginLineBottomPoint = circleAndLineIntersection({
      circle: { radius: bottomRadius, centerPoint },
      line: marginLine,
      returnBiggerXCoord: direction === "up",
    });

    return {
      topPoint: marginLineTopPoint,
      bottomPoint: marginLineBottomPoint,
    };
  } catch {
    return {
      topPoint,
      bottomPoint,
    };
  }
}

/**
 * Given the edge points of "pdf slot" arc, calculates the centre point of the circle this arc is a part of
 *
 * @param {{x: Decimal, y: Decimal}} topLeftCorner
 * @param {{x: Decimal, y: Decimal}} topRightCorner
 * @param {{x: Decimal, y: Decimal}} bottomLeftCorner
 * @param {{x: Decimal, y: Decimal}} bottomRightCorner

 * @returns null or point in the form {x: Decimal, y: Decimal} (should not return null in the cases we are applying it to)
 */
function calcCenterPoint({
  topLeftCorner,
  topRightCorner,
  bottomLeftCorner,
  bottomRightCorner,
}) {
  const leftEdgeLine = getLine(topLeftCorner, bottomLeftCorner);
  const rightEdgeLine = getLine(topRightCorner, bottomRightCorner);

  return linesIntersection(leftEdgeLine, rightEdgeLine);
}

export function initialPrintingMeasurementsEnhanced(
  initialMeasurements,
  correctionDirection = "left",
  smallPrintImageApproximateHeight,
  smallPrintImageAdditionalYOffset
) {
  // for intermidiate internal math calculations we convert ordinary numbers from initialMeasurements into Decimal objects
  const initialMeasurementsAsDecimalObject = Object.keys(
    initialMeasurements
  ).reduce(
    (acc, key) => ({
      ...acc,
      [key]: ordinaryPointToDecimalPoint(initialMeasurements[key]),
    }),
    {}
  );

  let { topLeftCorner, topRightCorner, bottomLeftCorner, bottomRightCorner } =
    initialMeasurementsAsDecimalObject;

  // for explanation for the need of this "correction" see documentation/geometry/circular_arc_restrictions.pdf
  if (correctionDirection === "left") {
    topLeftCorner = calculateCorrectedTopLeftCorner({
      topRightCorner,
      bottomLeftCorner,
      bottomRightCorner,
    });
  } else {
    bottomRightCorner = calculateCorrectedBottomRightCorner({
      topLeftCorner,
      topRightCorner,
      bottomLeftCorner,
    });
  }

  const topWidth = distance(topLeftCorner, topRightCorner);
  const bottomWidth = distance(bottomLeftCorner, bottomRightCorner);
  const skewHeight = distance(topLeftCorner, bottomLeftCorner);

  // TODO: you can calculate both top radius and bottom radius just by considering distance from center point to one of the corner points, see documentation/geometry/circle_from_arc
  const topRadius = calcTopRadius({
    topWidth,
    bottomWidth,
    skewHeight,
  });

  const bottomRadius = topRadius.minus(skewHeight);

  const centerPoint = calcCenterPoint({
    topLeftCorner,
    topRightCorner,
    bottomLeftCorner,
    bottomRightCorner,
  });

  const initialBottomSlope = getLineSlope(bottomLeftCorner, bottomRightCorner);

  return {
    ...initialMeasurementsAsDecimalObject,
    topRadius,
    bottomRadius,
    centerPoint,
    skewHeight,
    initialBottomSlope,
    correctionDirection,
    smallPrintImageApproximateHeight: new Decimal(
      smallPrintImageApproximateHeight
    ),
    smallPrintImageAdditionalYOffset: new Decimal(
      smallPrintImageAdditionalYOffset
    ),
  };
}

/*
  For explanation of math involved see pdf documents in documentation/geometry/ folder

  This function takes original measurements specs of original circular arc resolved for an image,
  calculates new edge points of new circular arc after left and right margins are applied and return all the information
  both photo editor cropper tool and pdf generation tool need

  Returns object that contains the following information:
  "straightenedImageWidth" and "straightenedImageHeight" are used to determine the cropper area dimensions, so that after bending the image will have correct aspect ratio
  "topWidth" is the width of rectangular area reserved for the image in the pdf after it is bent and rotated. It is used to scale the image correctly when it is embedded in the pdf.
  "printCurveAngle" and "skewAngle" are used to bent the image and rotate it afterwards and both are consumed by imageMagick's "distort -Arc" command
  "bottomLeftEdgePoint" contains the coordinates of the left bottom corner point of the image, when it is embedded in the pdf templates first slot.
*/

export function getPhotoEditorSettings({
  measurementSpec: {
    topLeftCorner,
    topRightCorner,
    bottomLeftCorner,
    bottomRightCorner,
    topRadius,
    bottomRadius,
    centerPoint,
    skewHeight,
    initialBottomSlope,
    correctionDirection,
    smallPrintImageApproximateHeight,
    smallPrintImageAdditionalYOffset,
  },
  leftMargin = 0, // assumed non-positive
  rightMargin = 0, // assumed non-negative
  direction = "up", // now that also 20oz pdf has same direction as others, we don't really need this parameter anymore, since this will always be called with 'direction: "up"'. However let us keep it for now.
}) {
  let { topPoint: shiftedTopLeftCorner, bottomPoint: shiftedBottomLeftCorner } =
    calcMarginEdgePoints({
      topPoint: topLeftCorner,
      bottomPoint: bottomLeftCorner,
      centerPoint,
      topRadius,
      bottomRadius,
      marginLength: new Decimal(leftMargin).times(-1),
      direction,
    });

  let {
    topPoint: shiftedTopRightCorner,
    bottomPoint: shiftedBottomRightCorner,
  } = calcMarginEdgePoints({
    topPoint: topRightCorner,
    bottomPoint: bottomRightCorner,
    centerPoint,
    topRadius,
    bottomRadius,
    marginLength: new Decimal(rightMargin),
    direction,
  });

  // for explanation for the need of this "correction" see documentation/geometry/circular_arc_restrictions.pdf
  if (correctionDirection === "left") {
    shiftedTopLeftCorner = calculateCorrectedTopLeftCorner({
      topRightCorner: shiftedTopRightCorner,
      bottomLeftCorner: shiftedBottomLeftCorner,
      bottomRightCorner: shiftedBottomRightCorner,
    });
  } else {
    shiftedBottomRightCorner = calculateCorrectedBottomRightCorner({
      topLeftCorner: shiftedTopLeftCorner,
      topRightCorner: shiftedTopRightCorner,
      bottomLeftCorner: shiftedBottomLeftCorner,
    });
  }

  /*
    TODO: Above implies that out of 4 points shiftedTopLeftCorner, shiftedBottomLeftCorner, shiftedTopRightCorner, shiftedBottomRightCorner
    only 3 points are actually used, so this way does calcualtions that are not used. This can be optimized by clever refactoring.
  */

  const topWidth = distance(shiftedTopLeftCorner, shiftedTopRightCorner);
  const bottomWidth = distance(
    shiftedBottomLeftCorner,
    shiftedBottomRightCorner
  );

  // TODO: you can calculate new center point as intersection of two lines and new top radius by considering distance from center point to one of the corner points, see documentation/geometry/circle_from_arc
  const newTopRadius = calcTopRadius({
    topWidth,
    bottomWidth,
    skewHeight,
  });

  // TODO: you can also calculate this as a smaller angle between two lines, , see documentation/geometry/circle_from_arc
  const printCurveAngle = calcCurveAngle({ topWidth, topRadius: newTopRadius });

  const straightenedImageWidth = calcStraigtenedImageWidth({
    topRadius: newTopRadius,
    skewHeight,
    curveAngle: printCurveAngle,
  });

  const straightenedImageHeight = skewHeight;
  const bottomLine = getLine(shiftedBottomLeftCorner, shiftedBottomRightCorner);

  // the bottom left point edge of the image after it is bent and rotated by skewAngle
  const bottomLeftEdgePoint = {
    x: shiftedBottomLeftCorner.x,
    y: shiftedTopLeftCorner.y,
  };

  const skewAngle = angleBetweenLines({
    slope1: initialBottomSlope,
    slope2: bottomLine.slope,
  });

  return decimalObjectToNumberObject({
    topWidth: topWidth.times(skewAngle.cos()), // the width the image should be when it is put in pdf (after it is bent and slightly rotated by skewAngle)
    straightenedImageWidth,
    straightenedImageHeight,
    printCurveAngle: radiansToDegree(printCurveAngle),
    skewAngle: radiansToDegree(skewAngle),
    bottomLeftEdgePoint,
    smallPrintImageApproximateHeight,
    smallPrintImageAdditionalYOffset,
  });
}

/* See documentation/geometry/circular_arc_restrictions.pdf for explanation on necessity of this function */
function calculateCorrectedTopLeftCorner({
  topRightCorner,
  bottomLeftCorner,
  bottomRightCorner,
}) {
  const bottomLine = getLine(bottomLeftCorner, bottomRightCorner);
  const bottomLineMiddle = lineMiddle(bottomLeftCorner, bottomRightCorner);

  const middleVerticalLineSlope = getPerpendicularLineSlope(bottomLine.slope);
  const middleVerticalLine = getLineBySlopeAndPoint(
    middleVerticalLineSlope,
    bottomLineMiddle
  );

  const topLine = getLineBySlopeAndPoint(bottomLine.slope, topRightCorner);
  const topLineMiddle = linesIntersection(middleVerticalLine, topLine);

  const halfDistanceToTopLeftCorner = distance(topRightCorner, topLineMiddle);

  if (topLineMiddle.x === topRightCorner.x) {
    const yDirectionUp = topRightCorner.y.lt(topLineMiddle.y);
    const yChange = yDirectionUp
      ? halfDistanceToTopLeftCorner.times(2)
      : halfDistanceToTopLeftCorner.times(-2);

    return {
      x: topRightCorner.x,
      y: topRightCorner.y.plus(yChange),
    };
  }

  return circleAndLineIntersection({
    circle: {
      radius: halfDistanceToTopLeftCorner,
      centerPoint: topLineMiddle,
    },
    line: topLine,
    returnBiggerXCoord: topLineMiddle.x.gt(topRightCorner.x),
  });
}

/* See documentation/geometry/circular_arc_restrictions.pdf for explanation on necessity of this function */
function calculateCorrectedBottomRightCorner({
  topLeftCorner,
  topRightCorner,
  bottomLeftCorner,
}) {
  const topLine = getLine(topLeftCorner, topRightCorner);
  const topLineMiddle = lineMiddle(topLeftCorner, topRightCorner);

  const middleVerticalLineSlope = getPerpendicularLineSlope(topLine.slope);
  const middleVerticalLine = getLineBySlopeAndPoint(
    middleVerticalLineSlope,
    topLineMiddle
  );

  const bottomLine = getLineBySlopeAndPoint(topLine.slope, bottomLeftCorner);
  const bottomLineMiddle = linesIntersection(middleVerticalLine, bottomLine);

  const halfDistanceToBottomRightCorner = distance(
    bottomLeftCorner,
    bottomLineMiddle
  );

  if (bottomLineMiddle.x === bottomLeftCorner.x) {
    const yDirectionUp = bottomLeftCorner.y.lt(bottomLineMiddle.y);
    const yChange = yDirectionUp
      ? halfDistanceToBottomRightCorner.times(2)
      : halfDistanceToBottomRightCorner.times(-2);

    return {
      x: bottomLeftCorner.x,
      y: bottomLeftCorner.y.plus(yChange),
    };
  }

  return circleAndLineIntersection({
    circle: {
      radius: halfDistanceToBottomRightCorner,
      centerPoint: bottomLineMiddle,
    },
    line: bottomLine,
    returnBiggerXCoord: bottomLeftCorner.x.lt(bottomLineMiddle.x),
  });
}

function lineMiddle(point1, point2) {
  return {
    x: average(point1.x, point2.x),
    y: average(point1.y, point2.y),
  };
}

function average(num1, num2) {
  return num1.plus(num2).div(2);
}
