import React from "react";

import logo from "../assets/logo.svg";
import LangSelector from "./LangSelector";

import "../styles/header.scss";

export default function ConfiguratorHeader() {
  const handleExit = () => {
    document.getElementsByTagName("footer")[0].classList.remove("hide-element");
    document.getElementsByTagName("header")[0].classList.remove("hide-element");
    document
      .getElementsByClassName("configurator-container-plugin")[0]
      .classList.remove("--opened");
  };

  return (
    <div className="configurator-header">
      <div className="container">
        <div className="header-wrapper">
          <img src={logo} width="15%" alt="Cupbio" />

          <div className="button-wrapper">
            <LangSelector />
            <div onClick={handleExit} className="exit-button">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
