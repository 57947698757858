import create from "zustand";

export const marginDirections = ["top", "bottom", "left", "right"];

const cupSizes = [
  {
    value: "2oz",
    label: "60ml",
    margins: {
      top: 4,
      bottom: 2,
      left: -1,
      right: 6,
    },
  },
  {
    value: "4oz",
    label: "120ml",
    margins: {
      top: 3,
      bottom: 3,
      left: -1,
      right: 5,
    },
  },
  {
    value: "8oz",
    label: "240ml",
    defaultSelected: true,
    margins: {
      top: 6,
      bottom: 4,
      left: -1,
      right: 6,
    },
  },
  {
    value: "12ozDW",
    label: "350ml DW",
    margins: {
      top: 1,
      bottom: 7,
      left: -1,
      right: 5,
    },
  },
  {
    value: "12oz",
    label: "350ml",
    margins: {
      top: 8,
      bottom: 10,
      left: -1,
      right: 7,
    },
  },
  {
    value: "16oz",
    label: "450ml",
    margins: {
      top: 8,
      bottom: 6,
      left: -1,
      right: 7,
    },
  },
  {
    value: "20oz",
    label: "590ml",
    margins: {
      top: 9,
      bottom: 6,
      left: -1,
      right: 4,
    },
  },
];

const getInitialSourceImageData = () => {
  try {
    const savedImageData = localStorage.getItem("img");

    if (!savedImageData) {
      return {};
    }

    const { data } = JSON.parse(savedImageData);
    // buffer cannot be saved to local storage, but we can recover it from "data"
    // this is browser equivalent of "Buffer.from(data, "base64")" we use in main
    // atob is considered depricated, but since it works here, and we only use this in dev mode, it shouldnt be a problem
    const buffer = Uint8Array.from(atob(data), (c) => c.charCodeAt(0));

    return { data, buffer };
  } catch {
    return {};
  }
};

const useStore = create((set) => ({
  lang: "fi",
  cupSizes,
  selectedCupSize: cupSizes.find(({ defaultSelected }) => defaultSelected)
    .value,

  setCupSize: (size) =>
    set(() => ({
      selectedCupSize: size,
    })),

  processState: 1,
  setProcessState: (processState) =>
    set(() => ({
      processState,
    })),

  cupType: "Hot Cupbio",
  setCupType: (cupType) => set(() => ({ cupType })),
  // Source image is the unedited photo uploaded by the user
  sourceImage: getInitialSourceImageData(),

  // Edited image modified by the image editor
  croppedImageFor3DPreview: "",

  // Processed image for printing
  processedImageForPrint: null,

  cropData: null,
  canvasData: null,
  croppingInProgress: false,

  drawBorderOnPDF: false,
  addSmallPrint: true,
  pdfSavingInProgress: false,
  setPdfSavingInProgress: (pdfSavingInProgress) =>
    set(() => ({
      pdfSavingInProgress,
    })),

  setMargins: (direction, amount) => {
    set(({ cupSizes, selectedCupSize }) => {
      const cupSpecIndex = cupSizes.findIndex(
        ({ value }) => value === selectedCupSize
      );

      if (!cupSpecIndex < 0) {
        // SHOULDNT HAPPEN
        return;
      }
      const cupSpec = cupSizes[cupSpecIndex];
      const newMargins = { ...cupSpec.margins, [direction]: amount };

      const newCupSpecs = [...cupSizes];
      newCupSpecs[cupSpecIndex] = {
        ...cupSpec,
        margins: newMargins,
      };

      return {
        cupSizes: newCupSpecs,
      };
    });
  },

  setLang: (lang) => {
    set(() => ({
      lang,
    }));
  },
  setSourceImage: (sourceImage) =>
    set(() => ({
      sourceImage,
    })),
  setCroppedImageFor3DPreview: (croppedImageFor3DPreview) =>
    set(() => ({
      croppedImageFor3DPreview,
    })),
  setProcessedImageForPrint: (processedImageForPrint) =>
    set(() => ({
      processedImageForPrint,
    })),
  setCropData: (cropData) =>
    set(() => ({
      cropData,
    })),
  setCanvasData: (canvasData) =>
    set(() => ({
      canvasData,
    })),
  setCroppingInProgress: (croppingInProgress) =>
    set(() => ({
      croppingInProgress,
    })),
  setDrawBorderOnPDF: (drawBorderOnPDF) =>
    set(() => ({
      drawBorderOnPDF,
    })),
  setAddSmallPrint: (addSmallPrint) =>
    set(() => ({
      addSmallPrint,
    })),
}));

export default useStore;
