import React, { useEffect, useRef } from "react";

import ImageCropper from "./ImageCropper.jsx";
import LoadingIndicator from "../LoadingIndicator.jsx";

import { getPhotoEditorSettings } from "../../utils/geometryUtils";
import { useCupSpec, mmToPt } from "../../utils/utils";
import useStore from "../../store";

import "../../styles/photoeditor.scss";

// // otherwise like "mmToPixels" but returns 0 if called with negative input, thus ignoring it
// const convertMmToPixels = (mm) => mmToPixels(Math.max(mm, 0));

const PhotoEditor = ({ makeCrop }) => {
  const cupSpec = useCupSpec();

  // otherwise like "mmToPt" but returns 0 if called with negative input, thus ignoring it
  const convertMmToPts = (mm) => mmToPt(Math.max(mm, 0));

  const {
    selectedCupSize,
    sourceImage,
    setCropData,
    setCanvasData,
    cupSizes,
    setMargins,
    pdfSavingInProgress,
    croppingInProgress,
  } = useStore();

  const cropperRef = useRef();
  const selectedCup = cupSizes.find(({ value }) => value === selectedCupSize);
  const { margins } = selectedCup;
  const { top = 0, bottom = 0, left = 0, right = 0 } = margins;

  useEffect(() => {
    setCropData(null);
    setCanvasData(null);
  }, [sourceImage, cupSpec, margins, setCanvasData, setCropData]);

  useEffect(() => {
    if (selectedCupSize === "2oz") {
      setMargins(left, 0);
    }
  }, [selectedCupSize, left, setMargins]);

  let topMarginInPoints = convertMmToPts(top);
  let bottomMarginInPoints = convertMmToPts(bottom);

  let leftMarginInPoints = convertMmToPts(-left);
  let rightMarginInPoints = convertMmToPts(right);

  const { straightenedImageWidth, straightenedImageHeight } =
    getPhotoEditorSettings({
      measurementSpec: cupSpec.printingSideMeasurements,
      leftMargin: leftMarginInPoints,
      rightMargin: rightMarginInPoints,
    });

  // Use these to prevent crop are sizes from being negative
  // Technically the limits in margin sizes should handle this also
  // but let's have this as a failsafe.

  if (topMarginInPoints + bottomMarginInPoints >= straightenedImageHeight) {
    topMarginInPoints = 0;
    bottomMarginInPoints = 0;
  }

  const cropAreaWidth = straightenedImageWidth;
  const cropAreaHeight =
    straightenedImageHeight - topMarginInPoints - bottomMarginInPoints;

  const { data } = sourceImage;

  return (
    <div className="photo-editor-wrapper">
      <LoadingIndicator text={"processingImage"} />
      <div
        className={`photo-editor ${
          pdfSavingInProgress || croppingInProgress ? "blur-01" : ""
        }`}
      >
        <br />
        {data && (
          <ImageCropper
            ref={cropperRef}
            makeCrop={makeCrop}
            cropAreaWidth={cropAreaWidth}
            cropAreaHeight={cropAreaHeight}
            data={data}
          />
        )}
      </div>
    </div>
  );
};

export default PhotoEditor;
