import React from "react";

import { useTexts } from "../texts";

import "../styles/uploadFile.scss";
import uploadImage from "../assets/upload-image.svg";

const UploadFile = ({ loadedFileName }) => {
  const texts = useTexts();
  const text =
    loadedFileName === ""
      ? texts("uploadButton")
      : `${texts("uploadButtonDone")} ${loadedFileName}`;

  return (
    <label
      className="upload-container"
      id="input-field-label"
      htmlFor="real-file-input"
    >
      <img src={uploadImage} />
      <label className="upload-button">{text}</label>
    </label>
  );
};

export default UploadFile;
