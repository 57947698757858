import React, { useRef, useEffect } from "react";
import {
  DoubleSide,
  BackSide,
  CylinderBufferGeometry,
  MathUtils,
  Texture,
  Vector3,
  LinearFilter,
} from "three";
import { Line } from "@react-three/drei";
import { useFrame, extend, useThree } from "@react-three/fiber";

import { Text } from "troika-three-text";

import useStore from "../store";
import { useCupSpec } from "../utils/utils";
import worksans from "../assets/worksans.ttf";

const initialMouseRef = { x: 0, y: 0, isPressed: false };
// Register Text as a react-three-fiber element
extend({ Text });

const cupDefaultColor = "white";

export default function GeometricCup() {
  const lang = useStore((store) => store.lang);
  const cupSpec = useCupSpec();

  const cup = useRef();
  const rotationGroup = useRef();
  const mouseRef = useRef(initialMouseRef);

  useEffect(() => {
    const onMousePress = () => {
      mouseRef.current.isPressed = true;
    };

    const onMouseRelease = () => {
      mouseRef.current.isPressed = false;
    };

    const onMouseMove = (event) => {
      if (!mouseRef.current.isPressed) return;
      mouseRef.current.x += event.movementX * 0.01;
      mouseRef.current.y += event.movementY * 0.005;
    };

    const viewer = document.querySelector(".model-viewer");

    if (!viewer) {
      // SHOULDNT HAPPEN
      return;
    }

    viewer.addEventListener("mousedown", onMousePress);
    viewer.addEventListener("mousemove", onMouseMove);
    viewer.addEventListener("mouseup", onMouseRelease);
    viewer.addEventListener("mouseout", onMouseRelease);

    return () => {
      viewer.removeEventListener("mousedown", onMousePress);
      viewer.removeEventListener("mousemove", onMouseMove);
      viewer.removeEventListener("mouseup", onMouseRelease);
      viewer.removeEventListener("mouseout", onMouseRelease);
    };
  }, []);

  useEffect(() => {
    // reset mouseRef value everytime cup size changes
    // note - shadow cloning is needed, otherwise we will mutate fields of initialMouseRef
    mouseRef.current = { ...initialMouseRef };
  }, [cupSpec]);

  useFrame(() => {
    if (cup.current) {
      rotationGroup.current.rotation.y = mouseRef.current.x;
      rotationGroup.current.rotation.x = mouseRef.current.y;
    }
  });

  const { paperThickness, doubleWall } = cupSpec;

  let dwInnerBottomConeTopRadius, dwBottomInnerDiameter;

  const {
    totalHeight,
    topOuterDiameter,
    topInnerDiameter,
    topTubeInnerDiameter,
    bottomDiameter,
    bottomDepth,
  } = cupSpec.cupMeasurements;

  if (doubleWall) {
    let { bottomInnerDiameter: diameter } = cupSpec.cupMeasurements;
    dwBottomInnerDiameter = diameter;
    dwInnerBottomConeTopRadius = dwBottomInnerDiameter / 2;
  }

  const tubeRadius = topTubeInnerDiameter / 2;
  // threjs docs: "Radius of the torus, from the center of the torus to the center of the tube"
  const torusRadius = (topOuterDiameter - topTubeInnerDiameter) / 2;
  const surfaceTopRadius = topOuterDiameter / 2 - topTubeInnerDiameter;

  const topConeHeight = totalHeight - tubeRadius;

  const bottomConeHeight = bottomDepth;
  const bottomConeTopRadius = bottomDiameter / 2;
  const bottomConeBottomRadius =
    groundPlaneBottomDiameter(
      totalHeight,
      bottomDepth,
      topInnerDiameter,
      doubleWall ? dwBottomInnerDiameter : bottomDiameter
    ) / 2;

  const cupScale = 1.5 / totalHeight;

  const lineXCoord = -1.2;

  const text = `${totalHeight.toLocaleString(lang)} mm`;
  return (
    <group position={[0, 1, 0]} scale={[1.5, 1.5, 1.5]}>
      <Line
        position={[0, -1, 0]}
        lineWidth={1.3}
        points={[
          new Vector3(lineXCoord, 0, 0),
          new Vector3(lineXCoord, totalHeight * cupScale, 0),
        ]}
        color="#09241A"
      />
      <text
        position-x={-1.35}
        position-y={-0.15}
        rotation-z={Math.PI / 2}
        text={text}
        font={worksans}
        fontSize={0.15}
        fontWeight={500}
        lineHeight={1.5}
        letterSpacing={0.02}
        anchorX="center"
        anchorY="middle"
        color="black"
      />
      <group ref={rotationGroup}>
        <group
          position={[0, -1, 0]}
          scale={[cupScale, cupScale, cupScale]}
          rotation-y={3.5}
          ref={cup}
        >
          <group position={[0, bottomConeHeight, 0]}>
            <CupTop
              positionY={topConeHeight}
              tubeRadius={tubeRadius}
              torusRadius={torusRadius}
            />
            <CupSurface
              height={topConeHeight}
              topRadius={surfaceTopRadius}
              bottomRadius={bottomConeTopRadius}
              bottomConeHeight={bottomConeHeight}
              doubleWall={doubleWall}
              drawTexture={true}
            />
            {/* Create extra cup surface to the draw the second wall of double wall cup */}
            {doubleWall ? (
              <CupSurface
                height={topConeHeight}
                topRadius={surfaceTopRadius}
                bottomRadius={dwInnerBottomConeTopRadius}
                bottomConeHeight={bottomConeHeight}
                doubleWall={doubleWall}
                drawTexture={false}
              />
            ) : (
              ""
            )}

            {doubleWall ? (
              // Create second cup bottom for double wall cup to give illusion of cup bottom depth
              <CupBottom
                positionY={-bottomConeHeight / 2}
                topRadius={
                  doubleWall ? dwInnerBottomConeTopRadius : bottomConeTopRadius
                }
                bottomRadius={bottomConeBottomRadius}
                height={bottomConeHeight + 6}
                topRadiusOffset={1}
                bottomRadiusOffset={1}
                openRender={true}
              />
            ) : (
              <CupBottom
                positionY={-bottomConeHeight / 2}
                topRadius={
                  doubleWall ? dwInnerBottomConeTopRadius : bottomConeTopRadius
                }
                bottomRadius={bottomConeBottomRadius}
                height={bottomConeHeight}
                // Make top radius slightly larger to fill gaps between bottom and cup surface on non-double wall
                topRadiusOffset={doubleWall ? 0 : paperThickness}
                // Make bottom radius slightly smaller to give illusion of cup inner depth on non-double wall
                bottomRadiusOffset={doubleWall ? 0 : -paperThickness}
                openRender={false}
              />
            )}
            {doubleWall ? (
              // Create second cup bottom for double wall cup to give illusion of cup bottom depth
              <CupBottom
                positionY={-bottomConeHeight / 2 + 3}
                topRadius={
                  doubleWall ? dwInnerBottomConeTopRadius : bottomConeTopRadius
                }
                bottomRadius={bottomConeBottomRadius}
                height={bottomConeHeight}
                topRadiusOffset={1}
                bottomRadiusOffset={1}
                openRender={false}
              />
            ) : (
              ""
            )}
          </group>
        </group>
      </group>
    </group>
  );
}

function CupSurface({
  height,
  topRadius,
  bottomRadius,
  bottomConeHeight,
  doubleWall,
  drawTexture,
}) {
  let positionY;

  if (doubleWall) {
    positionY = height / 2;
  } else {
    height += bottomConeHeight;
    positionY = height / 2 - bottomConeHeight;
  }

  const geometry = new CylinderBufferGeometry(
    topRadius, // radiusAtTop
    bottomRadius, // radiusAtBottom
    height, // height
    100, //segmentsAroundRadius
    8,
    true
  );

  const cupPosition = [0, positionY, 0];

  return (
    <>
      {/* Outside of a cup cylinder */}
      <mesh position={cupPosition} geometry={geometry}>
        {/*
            Actual surface
            Note "attachArray" prop - it guarantees that material is applied only to the actual surface of the cone, not top or bottom
        */}
        {drawTexture ? (
          <CupSurfaceImageMesh doubleWall={doubleWall} />
        ) : (
          <meshStandardMaterial
            attachArray="material"
            color={cupDefaultColor}
            roughness={0.9}
          />
        )}
      </mesh>
      {/* Inside of a cup cylinder */}
      <mesh position={cupPosition} geometry={geometry}>
        <meshStandardMaterial
          attachArray="material"
          color={cupDefaultColor}
          side={BackSide}
        />
      </mesh>
    </>
  );
}

function CupTop({ positionY, torusRadius, tubeRadius }) {
  return (
    <mesh
      rotation={[MathUtils.degToRad(90), 0, 0]}
      position={[0, positionY, 0]}
    >
      <torusBufferGeometry args={[torusRadius, tubeRadius, 100, 100]} />
      <meshStandardMaterial color={cupDefaultColor} />
    </mesh>
  );
}

function CupBottom({
  positionY,
  topRadius,
  bottomRadius,
  height,
  topRadiusOffset,
  bottomRadiusOffset,
  openRender,
}) {
  return (
    <mesh position={[0, positionY, 0]}>
      <cylinderBufferGeometry
        args={[
          // Offsets are used to give illusion of depth to make cup bottom slightly different size
          topRadius + topRadiusOffset,
          bottomRadius + bottomRadiusOffset,
          height - 6,
          100,
          3,
          openRender,
        ]}
      />
      {/* Transparent material to hide the outside of the cup bottom, as we draw the texture Image
      all the way to the cup bottom. */}
      <meshStandardMaterial
        attachArray="material"
        side={DoubleSide}
        color={cupDefaultColor}
      />
      {/* This is used to draw the top of the cupbottom object, to make the cup's inside bottom visible. */}
      <meshStandardMaterial
        attachArray="material"
        color={cupDefaultColor}
        side={DoubleSide}
      />
    </mesh>
  );
}

// calculates the diameter of the very bottom of the cup (the part that touches the ground), using simple geometric calculations
function groundPlaneBottomDiameter(
  height, // total height of the cup from the very top
  bottomDepth, // the height of the cup "bottom"
  topDiameter, // the top diameter of the cup - given "inner diameter" can be used (not "outer diameter" coz it corresponds to the whole diameter of cup PLUS the "torus on top of it" )
  bottomDiameter // diameter at which the "bottom" occurs (the round bottom of the part that contains the actual drink)
) {
  return (
    (bottomDiameter - (bottomDepth * topDiameter) / height) /
    (1 - bottomDepth / height)
  );
}

/**
 * Creates a mesh for displaying edited images on the 3d cup surface.
 * Textures are applied to the mesh, which is applied then to the 3d geometry object.
 * @returns Standard material mesh with the cropped image mapped to its texture
 */
function CupSurfaceImageMesh({ doubleWall }) {
  const materialEl = useRef();

  const croppedImageFor3DPreview = useStore(
    (state) => state.croppedImageFor3DPreview
  );

  useEffect(() => {
    // everytime texture changes we need to dynamically call needsUpdate = true on the material object, otherwise old texture will remain
    if (materialEl.current) {
      materialEl.current.needsUpdate = true;
    }
  }, [croppedImageFor3DPreview]);

  const { gl } = useThree();
  const anisotropy = gl.capabilities.getMaxAnisotropy();
  const cupTexture = createCupTexture(croppedImageFor3DPreview, anisotropy);

  return (
    <>
      <meshStandardMaterial
        attachArray="material"
        color={cupDefaultColor}
        map={cupTexture}
        ref={materialEl}
        roughness={0.9}
      />
      {doubleWall ? (
        ""
        // Why is the following necessary?
        //<>
        //  <meshStandardMaterial
        //    attachArray="material"
        //    transparent={true}
        //    opacity={0}
        //  />
        //  <meshStandardMaterial
        //    attachArray="material"
        //    color={cupDefaultColor}
        //    roughness={0.9}
        //</>  />{" "}
        //</>
      ) : (
        ""
      )}
    </>
  );
}

/**
 * Creates texture for the cup from a user loaded image
 * @param {string} texture - The cropped image received from ImageMagick
 * @returns {THREE.Texture} Three.js texture for the cup
 */
function createCupTexture(texture, anisotropy) {
  if (!texture) {
    return null;
  }

  const { blob } = texture;

  const userTexture = new Texture();
  const image = new Image();
  var url = URL.createObjectURL(blob);
  image.src = url;

  image.onload = function () {
    userTexture.image = image;
    userTexture.anisotropy = anisotropy;
    userTexture.minFilter = LinearFilter;
    userTexture.needsUpdate = true;
  };

  return userTexture;
}
