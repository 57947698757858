import React, { forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import useStore from "../../store";
import { useTexts } from "../../texts";

import SwitchButton from "../SwitchButton";

const ImageCropper = (
  { makeCrop, cropAreaWidth, cropAreaHeight, data },
  cropperRef
) => {
  const { cropData, setCropData, setCanvasData, canvasData, selectedCupSize } =
    useStore();

  const navigate = useNavigate();
  const texts = useTexts();

  // Some intial value for now. TODO: We might want to make it scalable to screen width later on.
  const screenWidth = 1300;

  const aspectRatio = cropAreaWidth / cropAreaHeight;

  function cropperReady() {
    const cropper = cropperRef?.current?.cropper;

    // Set the previous crop data if it exists
    if (cropData) {
      /**
       * These are used to save the cropper state when changing between
       * 3D model view and Photo Editor with a cropped image.
       */
      cropper?.setCanvasData({
        left: Math.round(canvasData.left),
        top: Math.round(canvasData.top),
        width: Math.round(canvasData.width),
        height: Math.round(canvasData.height),
      });

      /**
       * We need to round the numbers so the data gets set correctly
       * when using cropper viewMode 1. Otherwise it doesn't work.
       */
      cropper?.setData({
        x: Math.round(cropData.x),
        y: Math.round(cropData.y),
        width: Math.round(cropData.width),
        height: Math.round(cropData.height),
      });
    } else {
      setCropData(cropper.getData());
      setCanvasData(cropper.getCanvasData());
    }
  }

  function cropperEndDone() {
    const cropper = cropperRef?.current?.cropper;
    setCropData(cropper.getData());
    setCanvasData(cropper.getCanvasData());
  }

  async function handleSwitch() {
    await makeCrop();
    navigate("/home/three-d");
  }

  let cropBoxScalingFactor = 1;
  switch (selectedCupSize) {
    case "2oz":
      cropBoxScalingFactor = 2;
      break;
    case "4oz":
      cropBoxScalingFactor = 1.75;
      break;
    case "8oz":
    case "12oz":
    case "16oz":
    case "20oz":
      cropBoxScalingFactor = 1.5;
      break;
    case "12ozDW":
      cropBoxScalingFactor = 1.4;
      break;
    default:
      break;
  }

  return (
    <div className="photo-editor-cropper">
      <SwitchButton
        buttonText={texts("3dmodel")}
        onClick={handleSwitch}
        name={"dSwitch"}
      />
      <Cropper
        src={data}
        style={{
          height: screenWidth / aspectRatio,
          width: "100%",
        }}
        aspectRatio={aspectRatio}
        autoCropArea={0.7}
        viewMode={2}
        guides={false}
        // Disable these to allow user to change cropping area size
        // cropBoxResizable={false}
        cropBoxMovable={true}
        minCropBoxWidth={200 * cropBoxScalingFactor}
        minCropBoxHeight={100 * cropBoxScalingFactor}
        // Prevent user to drag the container to create new crop boxes
        // It can be used to bypass cropBoxResizable=false
        toggleDragModeOnDblclick={false}
        dragMode={"move"}
        // Lower ratio for more precise zooming
        wheelZoomRatio={0.03}
        ready={cropperReady}
        cropend={cropperEndDone}
        ref={cropperRef}
      />
    </div>
  );
};

export default forwardRef(ImageCropper);
